import 'slick-carousel'
import 'magnific-popup';
import "./insta";

jQuery( document ).ready(function($) {
  // mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu').toggleClass('active')
  })
  $('.menu a').click(function(){
    $('.menu, .menu-trigger').removeClass('active')
  })

  // sliders
  $('#music .wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    adaptiveHeight: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>'
  });
  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>'
  });

  //Presave Popup
  if ($('#popup').length > 0){
    $.magnificPopup.open({
      items: {
        src: '#popup',
        type: 'inline'
      }
    });
  }
});